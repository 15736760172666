<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table-simple striped bordered small>
                              <b-tr>
                                <b-th>{{ $t('barc_config.committee_name') }}</b-th>
                                <b-td>{{ (this.$i18n.locale=='bn')? appCommitteeData.committee_name_bn : appCommitteeData.committee_name }}</b-td>
                                <b-th>{{ $t('barc_config.formation_date') }}</b-th>
                                <b-td>{{  appCommitteeData.formation_date | dateFormat }}</b-td>
                              </b-tr>
                              <b-tr>
                                <b-th>{{ $t('barc_config.committee_description') }}</b-th>
                                <b-td colspan="3">{{ (this.$i18n.locale=='bn')? appCommitteeData.description_bn : appCommitteeData.description }}</b-td>
                              </b-tr>
                          </b-table-simple>
                          <b-table-simple striped bordered small class="mt-3">
                              <b-tr>
                                <b-th>{{ $t('barc_config.start_date') }}</b-th>
                                <b-td>{{  appCommitteeData.commitee_details.start_date | dateFormat }}</b-td>
                                <b-th>{{ $t('barc_config.end_date') }}</b-th>
                                <b-td>{{  appCommitteeData.end_date | dateFormat }}</b-td>
                              </b-tr>
                          </b-table-simple>
                          <b-table-simple striped bordered small class="mt-3">
                              <b-tr>
                                <b-th class="text-center">{{ $t('commitee-setup.roll_namel') }}</b-th>
                              </b-tr>
                              <b-tr v-for="(role, index) in appCommitteeData.commitee_details" :key="index">
                                <b-td class="text-center"> {{ $i18n.locale === 'bn' ? role.roll_name_bn : role.roll_name }}</b-td>
                              </b-tr>
                          </b-table-simple>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.appCommitteeData = this.item
  },
  data () {
    return {
      appCommitteeData: [],
      slOffset: 1
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
