<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                      <b-row>
                        <b-col lg="6" md="6" sm="12" xs="12">
                          <ValidationProvider name="Circular Memo No" vid="mas_circular_info_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="mas_circular_info_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('commitee-setup.circular_memo_no') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                id="mas_circular_info_id"
                                plain
                                v-model="AppCommittee.mas_circular_info_id"
                                :options="circularList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" md="6" sm="12" xs="12">
                          <ValidationProvider name="Formation Date" vid="formation_date" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="formation_date"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('barc_config.formation_date')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-input class="form-control"
                                      v-model="AppCommittee.formation_date"
                                      placeholder="Select Date"
                                      id="datepicker"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" md="6" sm="12" xs="12">
                          <ValidationProvider name="Committee Name (En)" vid="committee_name" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="committee_name"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('barc_config.committee_name_en')}} <span class="text-danger">*</span>
                          </template>
                            <b-form-input
                                id="committee_name"
                                v-model="AppCommittee.committee_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" md="6" sm="12" xs="12">
                            <ValidationProvider name="Committee Name (Bn)" vid="committee_name_bn" rules="required">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="committee_name_bn"
                                slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                {{$t('barc_config.committee_name_bn')}} <span class="text-danger">*</span>
                            </template>
                              <b-form-input
                                  id="committee_name_bn"
                                  v-model="AppCommittee.committee_name_bn"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                          <ValidationProvider name="Description (En)" vid="description" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="description"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('barc_config.committee_description_en')}} <span class="text-danger">*</span>
                          </template>
                            <b-form-textarea
                                id="description"
                                v-model="AppCommittee.description"
                                rows="1"
                                max-rows="2"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" md="6" sm="12" xs="12">
                          <ValidationProvider name="Description (Bn)" vid="description_bn" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="description_bn"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('barc_config.committee_description_bn')}} <span class="text-danger">*</span>
                          </template>
                            <b-form-textarea
                                id="description_bn"
                                v-model="AppCommittee.description_bn"
                                rows="1"
                                max-rows="2"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <template>
                        <div style="font-size:12px; background-color: #66CC99; padding:6px">
                            <h5 class="text-white text-center"> {{ $t('commitee-setup.multiple_roll_table') }}</h5>
                        </div>
                      </template>
                      <b-row v-for="(detail, index) in AppCommittee.commitee_details" :key="index" class="mt-3">
                        <b-col lg="5" md="5" sm="12" xs="12">
                          <ValidationProvider name="Role Name (En)" vid="roll_name" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="roll_name"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('commitee-setup.roll_name')}} <span class="text-danger">*</span>
                          </template>
                            <b-form-input
                                id="roll_name"
                                v-model="detail.roll_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="5" md="5" sm="12" xs="12">
                          <ValidationProvider name="Role Name (Bn)" vid="roll_name_bn" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="roll_name_bn"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                              {{$t('commitee-setup.roll_name_bn')}} <span class="text-danger">*</span>
                          </template>
                            <b-form-input
                                id="roll_name_bn"
                                v-model="detail.roll_name_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xl="2" lg="2" sm="4" class="mt-5">
                          <b-button v-show="index == AppCommittee.commitee_details.length-1" variant=" iq-bg-success" size="sm" class="mr-1" @click="addRoll()"><i class="ri-add-line m-0"></i></b-button>
                          <b-button v-show="index || ( !index && AppCommittee.commitee_details.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                        </b-col>
                      </b-row>
                      <template>
                        <div style="font-size:12px; background-color: #66CC99; padding:6px">
                            <h5 class="text-white text-center"> {{ $t('barc_config.committee_duration') }}</h5>
                        </div>
                      </template>
                      <b-row class="mt-4">
                      </b-row>
                      <b-row>
                        <b-col lg="6" md="6" sm="12" xs="12">
                          <ValidationProvider name="Start Date" vid="start_date" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="start_date"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('barc_config.start_date')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-input class="form-control"
                                      v-model="AppCommittee.start_date"
                                      placeholder="Select Date"
                                      id="datepicker"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" md="6" sm="12" xs="12">
                          <ValidationProvider name="End Date" vid="end_date" rules="required">
                            <b-form-group
                              class="row"
                              label-cols-sm="12"
                              label-for="end_date"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('barc_config.end_date')}} <span class="text-danger">*</span>
                              </template>
                                <b-form-input class="form-control"
                                      v-model="AppCommittee.end_date"
                                      placeholder="Select Date"
                                      id="datepicker"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                          <div class="col-sm-3"></div>
                          <div class="col text-right">
                              <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                              &nbsp;
                              <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                          </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { appCommitteeStore, appCommitteeUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getAppCommitteeData()
      this.AppCommittee = tmp
    }
  },
  mounted () {
    core.index()
    flatpickr('#datepicker', {})
  },
  data () {
    return {
      loading: false,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      AppCommittee: {
        mas_circular_info_id: 0,
        committee_name: '',
        committee_name_bn: '',
        description: '',
        description_bn: '',
        formation_date: '',
        start_date: '',
        end_date: '',
        commitee_details: [
          {
            roll_name: '',
            roll_name_bn: ''
          }
        ]
      }
    }
  },
  computed: {
    circularList: function () {
     const circularList = this.$store.state.incentiveGrant.commonObj.circularInfoList.filter(item => item.status === 1)
       return circularList.map(item => {
        return Object.assign({}, item, { value: item.value, text: item.memo_no })
      })
    }
  },
  methods: {
    getAppCommitteeData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
          result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${appCommitteeUpdate}/${this.id}`, this.AppCommittee)
      } else {
          result = await RestApi.postData(incentiveGrantServiceBaseUrl, appCommitteeStore, this.AppCommittee)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      this.loading = false
      if (result.success) {
          this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })

          this.$bvModal.hide('modal-4')
      } else {
          this.$refs.form.setErrors(result.errors)
      }
    },
    addRoll () {
      const tampbpSetup = {
              roll_name: '',
              roll_name_bn: ''
      }
      const key1 = parseInt(this.AppCommittee.commitee_details.length - 1)
      const item = this.AppCommittee.commitee_details[key1]
      let isEmpty = true
      Object.keys(item).map(key => {
          if (item[key] === '' || item[key] === 0) {
              isEmpty = false
          }
      })
      if (isEmpty === true) {
        this.AppCommittee.commitee_details.push(tampbpSetup)
      }
    },
    remove (key) {
      this.AppCommittee.commitee_details.splice(key, 1)
    }
  }
}
</script>
