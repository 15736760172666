
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('barc_config.approval_committee_setup') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('barc_config.committee_name')"
                            label-for="committee_name"
                            >
                            <b-form-select
                                plain
                                v-model="search.committee_name"
                                :options="committeeList"
                                id="committee_name"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col  xs="12" sm="12" md="6">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="date_from"
                          >
                          <template v-slot:label>
                            {{$t('commitee-setup.committee_formation_from')}}
                          </template>
                          <b-form-input
                              id="datepicker"
                              v-model="search.date_from"
                          ></b-form-input>
                      </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col  xs="12" sm="12" md="6">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="date_to"
                          >
                          <template v-slot:label>
                            {{$t('commitee-setup.committee_formation_to')}}
                          </template>
                          <b-form-input
                              id="datepicker"
                              v-model="search.date_to"
                          ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('barc_config.approval_committee_setup_list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(formation_date)="data">
                                            {{ data.item.formation_date | dateFormat  }}
                                        </template>
                                        <template v-slot:cell(zone_name_bn)="data">
                                            {{ data.item.zone_name_bn }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                          <!-- <b-button class="mr-1" title="View Complain" v-b-modal.modal-5 variant=" iq-bg-success" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0 "></i></b-button>
                                          <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                          <b-button :variant="data.item.status === 1 ? ' iq-bg-success' : '  iq-bg-danger'" size="sm" @click="remove(data.item)">
                                              <i class="fas" :class="data.item.status ? 'fa-toggle-on' : 'fa-toggle-off'"></i>
                                          </b-button> -->
                                          <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 size="sm" @click="details(data.item)">
                                              <i class="fas fa-eye"></i>
                                          </a>
                                          <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                              <i class="ri-ball-pen-fill"></i>
                                          </a>
                                          <a href="javascript:" class="btn_table_action table_action_view" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                                              <i class="fas fa-toggle-on"></i>
                                          </a>
                                          <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 2">
                                              <i class="fa fa-toggle-off"></i>
                                          </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-5" size="lg" :title="$t('commitee-setup.selection_committee_information')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="item"></Details>
        </b-modal>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import Details from './Details'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { appCommitteeList, appCommitteeStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      search: {
        committee_name: 0,
        date_from: '',
        date_to: '',
        formation_date: ''
      },
      rows: [],
      item: ''
    }
  },
  mounted () {
    flatpickr('#datepicker', {})
  },
  computed: {
    formTitle () {
       return (this.editItemId === 0) ? this.$t('barc_config.approval_committee_setup') + ' ' + this.$t('globalTrans.entry') : this.$t('barc_config.selection_commitee')
    },
    committeeList: function () {
      return this.$store.state.incentiveGrant.commonObj.committeeList.filter(item => item.status === 1)
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('barc_config.committee_name'), class: 'text-center' },
          { label: this.$t('barc_config.formation_date'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'committee_name_bn' },
          { key: 'formation_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'committee_name' },
          { key: 'formation_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, appCommitteeStatus, item, 'incentive_grant', 'enlistedUniversityList')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, appCommitteeList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', response.data.data)
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    }
  }
}
</script>
